import { Formik, FormikProps } from 'formik';
import { ENABLE_PHARMACY_SEARCH } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { forwardRef, useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import Text from 'ui-kit/text/text';

import {
    PrescriptionPharmacyPayload,
    savePrescriptionPharmacy
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { setIsPharmacySearchFlow } from 'state/pharmacy/pharmacy.reducer';
import { pharmacySearchFlowSelector } from 'state/pharmacy/pharmacy.selector';

import { PHARMACY_SCHEMA } from 'schema/pharmacy.schema';

import { formatPhoneNumber } from 'util/string';

import './pharmacy-lookup.style.scss';
import PharmacySearchForm from './pharmacy-search-form-component';

export interface PharmacyInfoFormProps {
    initialValues: PrescriptionPharmacyPayload;
}

const PharmacyInfoForm = forwardRef(({ initialValues }: PharmacyInfoFormProps, ref) => {
    const formName = 'PharmacyInfoForm';
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isPharmacySearchFlow = useSelector(pharmacySearchFlowSelector);
    const pharmacyNameInput: any = useRef(null);
    const pharmacyPhoneInput: any = useRef(null);

    useEffect(() => {
        dispatch(setIsPharmacySearchFlow(false));
    }, []);

    const handleSubmitSelectedPharmacy = (values: PrescriptionPharmacyPayload) => {
        pharmacyNameInput.current.value = values.pharmacyName;
        pharmacyPhoneInput.current.value = formatPhoneNumber(values.pharmacyPhone);
        dispatch(savePrescriptionPharmacy(values));
    };

    const clearPrescriptionPharmacyForm = () => {
        const pharmacyPayload: PrescriptionPharmacyPayload = {
            pharmacyName: '',
            pharmacyPhone: '',
            pharmacyAddress: ''
        };
        pharmacyNameInput.current.value = '';
        pharmacyPhoneInput.current.value = '';

        dispatch(savePrescriptionPharmacy(pharmacyPayload));
        ref?.current.resetForm();
    };

    return (
        <Formik<PrescriptionPharmacyPayload>
            innerRef={ref as React.MutableRefObject<FormikProps<PrescriptionPharmacyPayload>>}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={handleSubmitSelectedPharmacy}
            validationSchema={PHARMACY_SCHEMA(t('countryCode'))}
            initialValues={initialValues}
        >
            {(formik) => (
                <Form
                    className="pharmacy-info-form"
                    id="pharmacy-info-form"
                    data-ga-form-name={formName}
                    onSubmit={(event) => {
                        handleSubmitSelectedPharmacy(event);
                    }}
                    autoComplete="off"
                >
                    <p className="form-instructions mb-1">{t('prescriptionPharmacyForm.pharmacyInformation.title')}</p>
                    <div className="form-description">
                        {t('prescriptionPharmacyForm.pharmacyInformation.enterDetails')}
                    </div>
                    <div className="pharmacy-info-form-fields">
                        <Text
                            name="pharmacyName"
                            label={t('prescriptionPharmacyForm.inputs.pharmacyName')}
                            onChange={(event) => {
                                formik.setFieldValue(event.target.name, event.target.value);
                            }}
                            errors={
                                formik.errors?.pharmacyName
                                    ? t('forms.errorMessages.requiredField', {
                                          label: t('prescriptionPharmacyForm.inputs.pharmacyName')
                                      })
                                    : undefined
                            }
                            touched={formik.touched.pharmacyName}
                            value={formik.values?.pharmacyName}
                            defaultValue={formik.initialValues.pharmacyName}
                            autoComplete="off"
                            inputRef={pharmacyNameInput}
                        />
                        <PhoneNumberText
                            name="pharmacyPhone"
                            label={t('prescriptionPharmacyForm.pharmacySearch.inputs.phone')}
                            onChange={(event) => {
                                formik.setFieldValue(event.target.name, event.target.value);
                            }}
                            errors={
                                formik.errors?.pharmacyPhone
                                    ? t('forms.errorMessages.requiredField', {
                                          label: t('prescriptionPharmacyForm.pharmacySearch.inputs.phone')
                                      })
                                    : undefined
                            }
                            countryCode={t(`countryCode`)}
                            touched={formik.touched.pharmacyPhone}
                            value={formik.values?.pharmacyPhone}
                            defaultValue={formik.initialValues.pharmacyPhone}
                            autoComplete="off"
                            inputRef={pharmacyPhoneInput}
                        />
                        <Button
                            variant="link"
                            className="text-options"
                            onClick={() => {
                                dispatch(setIsPharmacySearchFlow(!isPharmacySearchFlow));
                                if (!isPharmacySearchFlow) clearPrescriptionPharmacyForm();
                            }}
                        >
                            {t('prescriptionPharmacyForm.pharmacyInformation.searchYourPharmacy')}
                        </Button>
                    </div>
                    {ENABLE_PHARMACY_SEARCH && isPharmacySearchFlow && (
                        <PharmacySearchForm formik={formik} onSubmitSelectedPharmacy={handleSubmitSelectedPharmacy} />
                    )}
                </Form>
            )}
        </Formik>
    );
});

export default PharmacyInfoForm;
